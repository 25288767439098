import React, { Component } from 'react';

class Redirect extends Component {
    state = {};

    componentDidMount() {
        window.location.replace('https://www.twitter.com/basenug');
    }

    render() {
        return <div></div>;
    }
}

export default Redirect;
