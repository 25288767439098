import { BrowserRouter as Router, Routes, Route, useRoutes } from 'react-router-dom';
import Redirect from './components/redirect/Redirect';
// import Routes from './routes';

const Home = () => {
    return <h1>Hello world</h1>;
};

const App = () => {
    let routes = useRoutes([
        { path: '/', element: <Home /> },
        { path: '/qr', element: <Redirect /> },
        // ...
    ]);
    return routes;
};

const AppWrapper = () => {
    return (
        <Router>
            <App />
        </Router>
    );
};

export default AppWrapper;
